<template>
  <div class="grid grid-cols-1">
    <div v-if="!props.hideCloseIcon" class="col-span-1 h-0">
      <AKIcon class="ak-close-icon" :framework-icon="PrimeIcons.TIMES" :scale="1" @click="onClose" />
    </div>
    <div class="col-span-1">
      <div class="w-full">
        <div ref="editorContainer" class="ak-wysiwyg-editor" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface PropsInterface {
  placeholder?: string;
  autoFocus?: boolean;
  lazy?: boolean;
  hideCloseIcon?: boolean;
  enableMarkdownSwitch?: boolean;
}
import '@toast-ui/chart/dist/toastui-chart.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import '@toast-ui/editor-plugin-table-merged-cell/dist/toastui-editor-plugin-table-merged-cell.css';
import '@toast-ui/editor/dist/i18n/de-de';
import '@toast-ui/editor/dist/toastui-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import { akColors } from '@ak_tools/assets/ak_styles/vendors/tailwind/akColors'
import { AKIcon } from '@ak_tools/components/media'
// import AKTimings from '@ak_tools/ts_modules/core/AKTimings';
import { AKSVGIcons } from '@ak_tools/ts_modules/graphics/AKSVGIcons'
import { Editor, EditorOptions } from '@toast-ui/editor'
import chart from '@toast-ui/editor-plugin-chart'
import colorSyntax from '@toast-ui/editor-plugin-color-syntax'
import tableMergedCell from '@toast-ui/editor-plugin-table-merged-cell'
import uml from '@toast-ui/editor-plugin-uml'
import { PrimeIcons } from 'primevue/api'
import { nextTick, onBeforeUnmount, onMounted, onUnmounted, Ref, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n();
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  -------------------------------------------------------------------------------------------------------------------
const modelValue = defineModel<string | null>({ default: null });
const props = withDefaults(defineProps<PropsInterface>(), {
  placeholder: 'AKWYSIWYGTextEditor.default-placeholder',
  autoFocus: undefined,
  lazy: false,
  hideCloseIcon: false,
  enableMarkdownSwitch: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const resizeObserver = ref<ResizeObserver>();
const editorContainer = ref() as Ref<HTMLElement>;
let toastUIEditor: Editor | undefined = undefined;
let timeout: NodeJS.Timeout | null = null;
const internalValue = ref<string | null>(modelValue.value);
const editorHeight = ref(0);
const editorFocused = ref(false);
// const resizeObserverFilterWidths: number[] = [];
// const resizeObserverFilterHeights: number[] = [];
const exampleChartContent = `$$chart
  ,directory1,directory2
  Jan,21,23
  Feb,31,17
  type: column
  title: Monthly Revenue
  x.title: Amount
  y.title: Month
  y.min: 1
  y.max: 40
  y.suffix: $
  $$`;
const exampleUmlContent = `$$uml
partition Conductor {
  (*) --> "Climbs on Platform"
  --> === S1 ===
  --> Bows
}
partition Audience #LightSkyBlue {
  === S1 === --> Applauds
}
partition Conductor {
  Bows --> === S2 ===
  --> WavesArmes
  Applauds --> === S2 ===
}
partition Orchestra #CCCCEE {
  WavesArmes --> Introduction
  --> "Play music"
}
$$`;
const colorSyntaxOptions = {
  preset: [akColors.primary.DEFAULT, akColors.secondary.DEFAULT, '#8257FF', '#E81D0C', '#17E61C', '#CCC8C6'],
};
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const createEditor = () => {
  const defaultOptions = getDefaultEditorOptions();
  toastUIEditor = new Editor(defaultOptions);
  if (props.autoFocus) {
    toastUIEditor.moveCursorToEnd();
  }
  calculateNewHeight();
  setHeightEditingMode();
};
const getDefaultEditorOptions = () => {
  const options: EditorOptions = {
    usageStatistics: false,
    hideModeSwitch: !props.enableMarkdownSwitch,
    autofocus: props.autoFocus ? true : false,
    minHeight: '125px',
    placeholder: t(props.placeholder),
    language: 'de',
    initialValue: modelValue.value ? modelValue.value : '',
    initialEditType: 'wysiwyg',
    previewStyle: 'vertical',
    plugins: [uml, tableMergedCell, [colorSyntax, colorSyntaxOptions], chart],
    toolbarItems: [
      ['heading', 'bold', 'italic', 'strike'],
      ['hr', 'quote'],
      ['ul', 'ol', 'task', 'indent', 'outdent'],
      ['code', 'codeblock'],
      [
        'table',
        'image',
        'link',
        {
          el: createToolButton('chart', AKSVGIcons.chartIcon, exampleChartContent),
          command: 'chart',
          tooltip: t('AKWYSIWYGTextEditor.button.chart'),
          name: 'Chart',
        },
        {
          el: createToolButton('uml', AKSVGIcons.umlIcon, exampleUmlContent),
          command: 'uml',
          tooltip: t('AKWYSIWYGTextEditor.button.uml'),
          name: 'UML',
        },
      ],
    ],
    el: editorContainer.value,
    events: {
      change: () => updateValue(),
      blur: () => onBlur(),
      focus: () => setFocus(),
    },
  };
  return options;
};
const createToolButton = (toolName: string, innerHTML: string, exampleCode: string) => {
  const button = document.createElement('button');
  button.className = `toastui-editor-toolbar-icons ${toolName} ak-wysiwyg-svg-icon-size`;
  button.setAttribute('type', 'button');
  button.style.backgroundImage = 'none';
  button.style.margin = '0';
  button.innerHTML = innerHTML;
  button.addEventListener('click', () => {
    setMarkdown(`${getMarkdown()}\n${exampleCode}`);
  });
  return button;
};
const focusEditor = (moveToEnd?: boolean) => {
  toastUIEditor?.focus();
  if (moveToEnd) {
    toastUIEditor?.moveCursorToEnd();
  }
};
const listenToEscapeKeyDown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    if (editorFocused.value) {
      emitOnEscape();
    }
  }
};
const getPaddingFromStyles = (
  element: HTMLElement,
): {
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
} => {
  const computedStyles = getComputedStyle(element);
  const paddingTop = parseFloat(computedStyles.paddingTop);
  const paddingBottom = parseFloat(computedStyles.paddingBottom);
  const paddingLeft = parseFloat(computedStyles.paddingLeft);
  const paddingRight = parseFloat(computedStyles.paddingRight);
  return {
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  };
};
const calculateNewHeight = () => {
  const proseMirrorElementsH = editorContainer.value?.querySelectorAll(
    '.toastui-editor-contents h1, .toastui-editor-contents h2, .toastui-editor-contents h3, .toastui-editor-contents h4, .toastui-editor-contents h5, .toastui-editor-contents h6',
  );
  const proseMirrorElementsP = editorContainer.value?.querySelectorAll('.toastui-editor-contents > p');
  const proseMirrorElementsOL = editorContainer.value?.querySelectorAll('.toastui-editor-contents > ol > li > p');
  const proseMirrorElementsUL = editorContainer.value?.querySelectorAll('.toastui-editor-contents > ul > li > p');
  const proseMirrorElementsMisc = editorContainer.value?.querySelectorAll('.toastui-editor-contents blockquote');
  const proseMirrorElements = Array.from(proseMirrorElementsP || [])
    .concat(Array.from(proseMirrorElementsH || []))
    .concat(Array.from(proseMirrorElementsOL || []))
    .concat(Array.from(proseMirrorElementsUL || []))
    .concat(Array.from(proseMirrorElementsMisc || []));
  let newHeight = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Array.from(proseMirrorElements!).forEach((el: any) => {
    if (el instanceof HTMLElement) {
      const padding = getPaddingFromStyles(el);
      newHeight += el.offsetHeight + padding.paddingTop * 2 + padding.paddingBottom * 2;
    }
  });
  editorHeight.value = newHeight + 100;
};
const updateValue = () => {
  internalValue.value = getMarkdown();
  if (!props.lazy) {
    setModelValue();
  } else {
    delayModelUpdate();
  }
  emitOnInput();
};
const delayModelUpdate = () => {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    modelValue.value = internalValue.value;
    timeout = null;
  }, 2000);
};
const setModelValue = () => {
  modelValue.value = internalValue.value;
};
const onBlur = () => {
  setModelValue();
  editorFocused.value = false;
  emitOnBlur();
};
const onClose = () => {
  emitOnClose();
};
const setFocus = () => {
  editorFocused.value = true;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resizeDebounceObserver = (entries: ResizeObserverEntry[]) => {
  //TODO: check why buggy
  // for (const entry of entries) {
  //   if (entry.target === editorContainer.value) {
  //     resizeObserverFilterWidths.push(entry.contentRect.width);
  //     resizeObserverFilterHeights.push(entry.contentRect.width);
  //     let RedrawIt = true;
  //     if (resizeObserverFilterWidths.length > 2) {
  //       if (
  //         resizeObserverFilterWidths[0] == resizeObserverFilterWidths[1] &&
  //         resizeObserverFilterWidths[0] === resizeObserverFilterWidths[2] &&
  //         resizeObserverFilterHeights[0] === resizeObserverFilterHeights[2]
  //       ) {
  //         RedrawIt = false;
  //       }
  //       resizeObserverFilterWidths.shift();
  //       resizeObserverFilterHeights.shift();
  //     }
  //     if (RedrawIt) {
  //       if (!editorFocused.value) {
  //         AKTimings.debounce(() => redraw(), 1000);
  //       }
  //     }
  //   }
  // }
};
const redraw = () => {
  toastUIEditor?.destroy();
  createEditor();
};
const setMarkdown = (markdownText: string) => {
  toastUIEditor?.setMarkdown(markdownText);
};
const getMarkdown = () => {
  return toastUIEditor?.getMarkdown() ?? '';
};
const setHeightEditingMode = () => {
  toastUIEditor?.setHeight(`${editorHeight.value + 100}px`);
};
//  --------------------------------------------------------------------------------------------------------------------
//  watchers
//  --------------------------------------------------------------------------------------------------------------------
watch(internalValue, () => {
  calculateNewHeight();
  setHeightEditingMode();
});
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits<{
  onEscape: [];
  onInput: [];
  onBlur: [];
  onClose: [];
}>();
const emitOnEscape = () => {
  emits('onEscape');
};
const emitOnInput = () => {
  emits('onInput');
};
const emitOnBlur = () => {
  emits('onBlur');
};
const emitOnClose = () => {
  emits('onClose');
};
//  --------------------------------------------------------------------------------------------------------------------
//  provided data + public expose
//  --------------------------------------------------------------------------------------------------------------------
defineExpose({ focusEditor, getMarkdown, setMarkdown });
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  createEditor();
  // This is workaround of the bug within the Toast UI Editor. By default the toolbar is not rendering correctly.
  nextTick(() => {
    redraw();
  });
  resizeObserver.value = new ResizeObserver(resizeDebounceObserver);
  resizeObserver.value.observe(editorContainer.value);
  document.addEventListener('keydown', listenToEscapeKeyDown);
});
onBeforeUnmount(() => {
  resizeObserver.value?.unobserve(editorContainer.value);
  document.removeEventListener('keydown', listenToEscapeKeyDown);
});
onUnmounted(() => {
  resizeObserver.value?.disconnect();
});
</script>

<style lang="sass">
.ak-wysiwyg-svg-icon-size svg
  @apply w-6 h-6

.toastui-editor-popup-body
  input[type='text'].tui-colorpicker-palette-hex
    width: 100px

.toastui-editor-popup-color
  button
    top: 49px
    width: 40px
    height: 23px

  .tui-colorpicker-container
    .tui-colorpicker-palette-preview
      margin-top: 4px

.ak-close-icon
  @apply relative h-10 rounded-full p-4 float-end flex z-module-layer-flex-2

  &:hover
    @apply cursor-pointer brightness-150
</style>
