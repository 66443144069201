<template>
  <Dialog
    v-model:visible="visible"
    :auto-z-index="false"
    :class="[computedDefaultWidth, maximizedClass]"
    :pt="{
      content: {
        class: ['ak-scrollbar-primary'],
      },
      mask: {
        style: [zIndexStyle],
      },
      footer: {
        class: ['pt-4'],
      },
    }"
    :modal="props.blockBackground"
    :draggable="props.draggable"
    :content-style="props.contentStyle"
    :content-class="props.contentClass"
    :closable="props.showCloseButton"
    :maximizable="props.maximizable"
    :header="props.headlineText"
    :position="props.position"
    @hide="onDialogHide()"
    @update:visible="onUpdateVisible()">
    <template v-if="slotInUse.closeicon" #closeicon>
      <slot name="closeicon"></slot>
    </template>

    <template v-if="slotInUse.maximizeicon" #maximizeicon>
      <slot name="maximizeicon"></slot>
    </template>

    <template v-if="slotInUse.header" #header>
      <slot name="header"></slot>
    </template>

    <slot></slot>

    <template v-if="slotInUse.footer || props.showOkButton || props.showCancelButton" #footer>
      <slot name="footer">
        <AKButton
          v-if="props.showOkButton"
          data-test="ak-dialog-button-ok"
          label="OK"
          :disabled="props.okButtonDisabled"
          :icon="PrimeIcons.CHECK"
          :class="props.okButtonClass"
          @click="onOKButtonClick()" />
        <AKButton
          v-if="props.showCancelButton"
          data-test="ak-dialog-button-cancel"
          :label="$t('AKDialog.close-button-text')"
          :icon="PrimeIcons.TIMES"
          :has-background="false"
          :class="props.cancelButtonClass"
          @click="onCancelButtonClick()" />
      </slot>
    </template>
    <template v-if="slotInUse.container" #container="{ closeCallback }">
      <slot name="container" :close-callback="closeCallback" />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKLayersKey = keyof typeof akLayers
interface PropsInterface {
  cancelButtonClass?: string | undefined;
  contentClass?: string | object | undefined;
  contentStyle?: object | undefined;
  headlineText?: string | undefined;
  maximizable?: boolean;
  okButtonClass?: string | undefined;
  position?: 'center' | 'top' | 'bottom' | undefined;
  showCancelButton?: boolean;
  showOkButton?: boolean;
  showCloseButton?: boolean;
  zLayer?: _VTI_TYPE_AKLayersKey;
  draggable?: boolean;
  enableCustomWidth?: boolean;
  showMaximized?: boolean;
  blockBackground?: boolean;
  okButtonDisabled?: boolean;
}
import { akGetLayer } from '@ak_tools/assets/ak_styles/vendors/tailwind/akLayers'
import { AKButton } from '@ak_tools/components/button'
import { PrimeIcons } from 'primevue/api'
import Dialog from 'primevue/dialog'
import { computed, onMounted, useSlots } from 'vue'
//  --------------------------------------------------------------------------------------------------------------------
//  props
//  --------------------------------------------------------------------------------------------------------------------
const visible = defineModel<boolean>({ default: false });
const props = withDefaults(defineProps<PropsInterface>(), {
  cancelButtonClass: undefined,
  contentClass: undefined,
  contentStyle: undefined,
  headlineText: undefined,
  maximizable: false,
  okButtonClass: undefined,
  position: undefined,
  showCancelButton: true,
  showOkButton: true,
  showCloseButton: true,
  zLayer: 'wireframeOverlayLayer',
  draggable: false,
  enableCustomWidth: false,
  showMaximized: false,
  blockBackground: true,
  okButtonDisabled: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const slotInUse = useSlots();
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const zIndexStyle = computed(() => {
  return `z-index: ${akGetLayer(props.zLayer)};`;
});
const maximizedClass = computed(() => {
  return props.showMaximized ? 'p-dialog-maximized' : undefined;
});
const computedDefaultWidth = computed(() => {
  return !props.enableCustomWidth ? 'ak-dialog-default-width' : undefined;
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const onDialogHide = () => {
  visible.value = false;
  emitOnDialogHide();
};
const onUpdateVisible = () => {
  emitOnUpdateVisible();
};
const onOKButtonClick = () => {
  visible.value = false;
  emitOnOKClick();
};
const onCancelButtonClick = () => {
  visible.value = false;
  emitOnCancelClick();
};
//  --------------------------------------------------------------------------------------------------------------------
//  emits
//  --------------------------------------------------------------------------------------------------------------------
const emits = defineEmits(['cancelClick', 'okClick', 'dialogMounted', 'dialogHide', 'update:visible']);
const emitOnCancelClick = () => {
  emits('cancelClick');
};
const emitOnOKClick = () => {
  emits('okClick');
};
const emitOnDialogMounted = () => {
  emits('dialogMounted');
};
const emitOnDialogHide = () => {
  emits('dialogHide');
};
const emitOnUpdateVisible = () => {
  emits('update:visible');
};
//  --------------------------------------------------------------------------------------------------------------------
//  lifecycle
//  --------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  emitOnDialogMounted();
});
</script>

<style lang="sass">
.ak-dialog-default-width
  @apply w-[100vw] sm:w-[75vw] md:w-[65vw] #{!important}
</style>
