import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';
import {
  AKBECommLoginRequest,
  AKBECommLoginResponseR,
} from '@ak_tools/backend_communication/definitions/users/AKBECommSystemAccess';

export class AKBECommSystemAccess extends BeCommAll {
  public beCommLogout(messages?: AKBECommBaseMessages): Promise<AKBECommLoginResponseR> {
    return this.beComm.backendCommPost('/user/logout', {}, {}, messages);
  }
  public beCommLogin(params: AKBECommLoginRequest, messages?: AKBECommBaseMessages): Promise<AKBECommLoginResponseR> {
    return this.beComm.backendCommPost('/user/login', params, { timeout: 12000, retry: 3 }, messages, {
      returnOnError: false,
      retryOnBackendError: false,
    });
  }
  public beCommGetLoginState(): Promise<AKBECommLoginResponseR> {
    return this.beComm.backendCommGet('/user/login_state', {}, {});
  }
}
