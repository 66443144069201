<template>
  <span tabindex="0" v-text="props.userGroup.title" />
</template>

<script setup lang="ts">
type _VTI_TYPE_AKBECommUserGroupSchema = {
  user_group_id: number;
  title: string;
  abbr: string;
  description: string;
  source: AKBECommUserGroupSourceSchema | null;
  type: AKBECommUserGroupTypeSchema | null;
  active: boolean;
  child_groups: Array<AKBECommUserGroupChildSchema>;
  maintain_groups: Array<AKBECommUserGroupMaintainSchema>;
  parent_group_ids: Array<number>;
  child_group_ids: Array<number>;
  role: AKBECommRoleSchema | null;
  display_name: string;
  display_name_en: string;
  directory: AKBECommUserGroupDirectorySchema | null;
  private: boolean;
  restrict_user_visibility: boolean;
}
interface PropsInterface {
  userGroup: _VTI_TYPE_AKBECommUserGroupSchema;
}
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = defineProps<PropsInterface>();
</script>

<style lang="sass" scoped></style>
