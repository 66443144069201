<template>
  <AKCard>
    <template v-if="props.showHeader" #header>
      <div class="flex items-center justify-end gap-3 px-2 py-1">
        <AKTooltip :text="$t('AKEmployeeCard.icon.tooltip-text')">
          <AKIcon
            class="ak-card-header-button"
            :framework-icon="PrimeIcons.FILE_EDIT"
            :scale="1"
            @click="toggleShowUserGroup" />
        </AKTooltip>
      </div>
      <AKDivider class="mt-0" />
    </template>
    <div class="block space-y-1 p-3 sm:flex">
      <AKDialog v-model="uploadDialogIsVisible" :headline-text="$t('AKEmployeeCard.Upload-upload')">
        <AKDropzone @upload-files="uploadFiles" />
      </AKDialog>
      <div class="flex w-full flex-col gap-4 sm:w-1/3">
        <AKAvatar
          :key="avatarKey"
          class="text-theme mx-auto cursor-pointer bg-gray-500"
          size="xlarge"
          :full-name="akHR.getPersonFullName(props.personData)"
          :picture-path="avatarTemporary ? avatarTemporary : props.personData.picture_path"
          @mouseover="showUploadText"
          @mouseleave="showUploadText"
          @click="props.enablePictureUpload ? (uploadDialogIsVisible = true) : false">
          <div
            v-if="props.enablePictureUpload && uploadTextIsVisible"
            class="bg-primary-1 justify-content flex h-full w-full items-center rounded-full text-sm">
            <AKIcon v-if="!props.personData.picture_path" class="mx-auto" :framework-icon="PrimeIcons.UPLOAD" />
            <span v-if="props.personData.picture_path" class="mx-auto" :v-text="$t('AKEmployeeCard.upload-replace')" />
          </div>
        </AKAvatar>

        <div class="flex flex-col text-center">
          <span class="text-lg font-bold" v-text="akHR.getPersonFullName(props.personData)" />
          <div class="flex gap-2 self-center">
            <span v-text="'PN:'" />
            <span class="text-gray-400" v-text="akHR.getEmployeePersonalNumber(props.personData.employee)" />
          </div>
          <div v-if="showIconContainer" class="bg-backdrop mt-2 flex gap-3 self-center rounded px-3 pt-2">
            <AKTooltip
              v-if="props.personData.security_officer"
              class="counter-tooltip"
              placement="bottom"
              :text="$t('AKEmployeeCard.security-officer.tooltip')">
              <AKIcon :framework-icon="PrimeIcons.LOCK" />
            </AKTooltip>
            <AKTooltip
              v-if="props.personData.fire_protection_officer"
              class="counter-tooltip"
              placement="bottom"
              :text="$t('ZPBoardCardTooltipContent.fire-protection-officer.tooltip')">
              <AKIcon :framework-icon="PrimeIcons.SHIELD" />
            </AKTooltip>
            <AKTooltip
              v-if="props.personData.internal_security"
              class="counter-tooltip"
              placement="bottom"
              :text="$t('ZPBoardCardTooltipContent.internal-security.tooltip')">
              <AKIcon :framework-icon="PrimeIcons.PLUS_CIRCLE" />
            </AKTooltip>
          </div>
        </div>
      </div>

      <div class="flex w-full flex-col gap-2 sm:w-2/3">
        <div class="flex items-center gap-4">
          <AKTooltip :text="$t('AKEmployeeCard.tooltip.copy')">
            <AKIcon
              class="mt-1 cursor-pointer"
              :framework-icon="PrimeIcons.ENVELOPE"
              @click="copyContent(props.personData.user?.email || null)" />
          </AKTooltip>
          <a
            v-if="props.personData.user && props.personData.user.email"
            class="text-primary hover:brightness-75"
            :href="'mailto:' + props.personData.user.email"
            v-text="props.personData.user.email" />
          <span v-else class="text-gray-300">{{ $t('AKEmployeeCard.no-email') }}</span>
        </div>

        <div class="flex items-center gap-4">
          <AKIcon :framework-icon="PrimeIcons.PHONE" />
          <a
            v-if="props.personData.phone_number"
            class="text-primary hover:brightness-75"
            :href="'tel:' + props.personData.phone_number"
            v-text="formatNumber(props.personData.phone_number)" />
          <span v-else class="text-gray-300">{{ $t('AKEmployeeCard.no-phone-number') }}</span>
        </div>
        <div class="flex items-center gap-4">
          <AKIcon :framework-icon="PrimeIcons.BUILDING" />
          <span
            v-if="props.personData.employee?.main_org_unit_title"
            v-text="props.personData.employee?.main_org_unit_title" />
          <span v-else class="text-gray-300">{{ $t('AKEmployeeCard.no-org-unit') }}</span>
        </div>
        <div class="flex items-center gap-4">
          <AKIcon :framework-icon="PrimeIcons.MAP_MARKER" />
          <span v-if="props.personData.room_number">
            {{ $t('AKEmployeeCard.room') }} {{ props.personData.room_number }}
          </span>
          <span v-else class="text-gray-300">
            {{ $t('AKEmployeeCard.room-number') }}
          </span>
        </div>
        <div class="flex items-center gap-4">
          <AKIcon :framework-icon="PrimeIcons.PRINT" />
          <span v-if="props.personData.company_fax" v-text="props.personData.company_fax" />
          <span v-else class="text-gray-300">{{ $t('AKEmployeeCard.no-company-fax') }}</span>
        </div>
        <div class="flex items-center gap-4">
          <AKIcon :framework-icon="PrimeIcons.INBOX" />
          <div v-if="props.personData.po_box_number" class="flex gap-1">
            <span :v-text="$t('AKEmployeeCard.po-box')" />
            <span v-text="props.personData.po_box_number" />
          </div>
          <span v-else class="text-gray-300">{{ $t('AKEmployeeCard.no-po-box-number') }}</span>
        </div>
        <div class="flex items-center gap-4">
          <AKIcon :framework-icon="PrimeIcons.FILTER" />
          <div v-if="props.personData.pneumatic_tube_number" class="flex gap-1">
            <span :v-text="$t('AKEmployeeCard.pneumatic_tube_number')" />
            <span v-text="props.personData.po_box_number" />
          </div>
          <span v-else class="text-gray-300">{{ $t('AKEmployeeCard.no-pneumatic-tube-number') }}</span>
        </div>
        <div v-if="props.showGroups" class="flex gap-4">
          <AKIcon :framework-icon="PrimeIcons.USER" />
          <div class="space-y-1">
            <AKTag v-for="(item, index) in nonAdminGroups" :key="index">
              <span v-text="item.title" />
            </AKTag>
            <AKSelectUserGroup
              v-if="showSelectUserGroup"
              v-model="adminGroups"
              :label="false"
              multiselect
              :selectable-options="selectableOptions"
              :set-options-on-mount="false"
              prevent-backend-comm
              enable-switch-read-write-mode
              @on-select="addUserConUserGroupConRoleOnBackend"
              @on-remove="toggleConfirmAction" />
            <AKTag v-for="(item, index) in adminGroups" v-else :key="index">
              <span v-text="item.title" />
            </AKTag>
          </div>
          <span v-if="!props.personData.user?.groups" class="float-left text-gray-300">
            {{ $t('AKEmployeeCard.no-user-groups') }}
          </span>
        </div>
      </div>
    </div>
    <AKConfirmAction
      v-model="showRoleChangeConfirm"
      :headline="$t('AKEmployeeCard.ConfirmDialog.rolechange')"
      group="role-change-confirm"
      @on-confirm="applyRoleChange()"
      @on-cancel="showRoleChangeConfirm = false" />
  </AKCard>
</template>

<script setup lang="ts">
type _VTI_TYPE_AKBECommPersonEmployeeUserSchema = AKBECommPersonSchema & {
  employee: AKBECommEmployeeSchema | null;
  user: AKBECommUserSchema | null;
}
interface PropsInterface {
  personData: _VTI_TYPE_AKBECommPersonEmployeeUserSchema;
  enablePictureUpload?: boolean;
  showCardBorder?: boolean;
  showGroups?: boolean;
  showHeader?: boolean;
}
import { AKBECommUserGroupSmall } from '@ak_tools/backend_communication/definitions/hr/AKBECommPersons'
import { AKBECommSelectUserGroupFilter, AKBECommUserGroupSchema, AKRoleType } from '@ak_tools/backend_communication/definitions/users/AKBECommUsers'
import AKBECommFile from '@ak_tools/backend_communication/implementations/files/AKBECommFile'
import { AKBECommHR } from '@ak_tools/backend_communication/implementations/hr/AKBECommHR'
import { AKBECommUsers } from '@ak_tools/backend_communication/implementations/users/AKBECommUsers'
import { AKIcon } from '@ak_tools/components/media'
import { AKAvatar, AKDropzone, AKFileListItem, AKTag } from '@ak_tools/components/misc'
import { AKConfirmAction, AKTooltip } from '@ak_tools/components/overlay'
import { AKDialog } from '@ak_tools/components/overlay/'
import { AKCard } from '@ak_tools/components/panel'
import { AKVueProvider } from '@ak_tools/framework_support/AKVueProvider'
import { AKStore } from '@ak_tools/start/App'
import { AKHR } from '@ak_tools/ts_modules/hr/AKHR'
import { PrimeIcons } from 'primevue/api'
import { computed, ref } from 'vue'
import { AKSelectUserGroup } from '../select'
import AKDivider from './AKDivider.vue'
//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = withDefaults(defineProps<PropsInterface>(), {
  enablePictureUpload: false,
  showCardBorder: true,
  showGroups: true,
  showHeader: false,
});
//  --------------------------------------------------------------------------------------------------------------------
//  component variables
//  --------------------------------------------------------------------------------------------------------------------
const selectableOptions = ref<AKBECommUserGroupSchema[]>([]);
const selectedUserGroup = ref<AKBECommUserGroupSchema | AKBECommUserGroupSmall>();
const akHR = new AKHR();
const uploadTextIsVisible = ref(false);
const uploadDialogIsVisible = ref(false);
const backendCommHR = new AKBECommHR(new AKVueProvider());
const beCommFiles = new AKBECommFile(new AKVueProvider());
const beCommUsers = new AKBECommUsers(new AKVueProvider());
const avatarKey = ref(0);
const avatarTemporary = ref('');
const showRoleChangeConfirm = ref(false);
//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const showIconContainer = computed(() => {
  return props.personData.fire_protection_officer ||
    props.personData.internal_security ||
    props.personData.security_officer
    ? true
    : false;
});
const showSelectUserGroup = ref(false);
const nonAdminGroups = computed(() => {
  return (
    props.personData.user!.groups?.filter(
      (group) =>
        !selectableOptions.value.some((selectableGroup) => selectableGroup.user_group_id === group.user_group_id),
    ) ?? []
  );
});
const adminGroups = computed(() => {
  return (
    props.personData.user!.groups?.filter((group) =>
      selectableOptions.value.some((selectableGroup) => selectableGroup.user_group_id === group.user_group_id),
    ) ?? []
  );
});
//  --------------------------------------------------------------------------------------------------------------------
//  component logic
//  --------------------------------------------------------------------------------------------------------------------
const toggleShowUserGroup = () => {
  showSelectUserGroup.value = !showSelectUserGroup.value;
  getSelectableOptionsFromBackend();
  getUserAdminGroups();
};
const copyContent = (content: string | null) => {
  if (!content) {
    return;
  }
  navigator.clipboard.writeText(content);
};
const applyRoleChange = () => {
  showRoleChangeConfirm.value = false;
  deleteUserConUserGroupConRoleOnBackend(selectedUserGroup.value!);
};
const toggleConfirmAction = (userGroup: AKBECommUserGroupSchema | AKBECommUserGroupSmall) => {
  showRoleChangeConfirm.value = true;
  selectedUserGroup.value = userGroup;
};
const formatNumber = (phoneNumber: string) => {
  return phoneNumber ? phoneNumber.replace(/\D/g, '') : false;
};
const showUploadText = (event: Event) => {
  if (event.type == 'mouseover' && !uploadTextIsVisible.value) {
    uploadTextIsVisible.value = true;
  } else if (event.type == 'mouseleave' && uploadTextIsVisible.value) {
    uploadTextIsVisible.value = false;
  }
};
const getSelectableOptionsFromBackend = () => {
  beCommUsers
    .beCommGetUserGroupsByFilter(AKBECommSelectUserGroupFilter.all_my_admin_and_maintainer_user_groups)
    .then((response) => {
      selectableOptions.value = response.data.user_groups;
    });
};
const getUserAdminGroups = () => {
  beCommUsers.beCommGetUserAdminGroups(props.personData.user!.personal_user_group!.user_group_id).then((response) => {
    const userGroups = response.data.user_groups;
    selectableOptions.value = selectableOptions.value.filter((selectableGroup) => {
      return !userGroups.some((userGroup) => userGroup.user_group_id === selectableGroup.user_group_id);
    });
  });
};
const addUserConUserGroupConRoleOnBackend = (userGroup: AKBECommUserGroupSchema | AKBECommUserGroupSmall) => {
  beCommUsers
    .beCommCreateUserGroupConUserConRole(userGroup.user_group_id, props.personData.user!.user_id, AKRoleType.user)
    .then(() => {
      props.personData.user!.groups!.push(userGroup as AKBECommUserGroupSmall);
    });
};
const deleteUserConUserGroupConRoleOnBackend = (userGroup: AKBECommUserGroupSchema | AKBECommUserGroupSmall) => {
  beCommUsers
    .beCommDeleteUserGroupConUserConRole(userGroup.user_group_id, props.personData.user!.user_id, AKRoleType.user)
    .then(() => {
      const index = props.personData.user!.groups!.findIndex(
        (group) => group.user_group_id === userGroup.user_group_id,
      );
      props.personData.user!.groups!.splice(index, 1);
    });
};
const uploadFiles = (files: AKFileListItem[]) => {
  files.forEach((file) => {
    backendCommHR
      .beCommProfilePicturePrepareUpload({ person_id: props.personData.person_id, filename: file.name })
      .then(() => {
        beCommFiles.beCommFileUpload(Array<Blob>(file.fileObject!)).then(() => {
          backendCommHR
            .beCommProfilePictureFinalizeUpload({
              person_id: props.personData.person_id,
              filename: file.name,
            })
            .then((response) => {
              if (response.data.ready) {
                avatarKey.value += 1;
                avatarTemporary.value = '/profile_picture/' + props.personData.person_id + '/' + file.name;
                const personIndexInStore = AKStore.HR.state.selectablePersons.findIndex(
                  (person) => person.person_id == props.personData.person_id,
                );
                AKStore.HR.state.selectablePersons[personIndexInStore].picture_path = avatarTemporary.value;
                uploadDialogIsVisible.value = false;
              }
            });
        });
      });
  });
};
</script>

<style lang="sass" scoped>
.ak-card-header-button
    @apply cursor-pointer rounded p-2 text-gray-400 hover:bg-gray-200 hover:text-gray-500
</style>
