import { BeCommAll } from '@ak_tools/backend_communication/AKBEComm';
import { AKBECommBaseMessages } from '@ak_tools/backend_communication/AKBECommBase';
import {
  AKBECommGenerateAndVerifyUserGroupDirectoryNameRequest,
  AKBECommGenerateAndVerifyUserGroupDirectoryNameResponseR,
  AKBECommGenerateAndVerifyUserGroupNameRequest,
  AKBECommGenerateAndVerifyUserGroupNameResponseR,
  AKBECommMaintainUserGroupDeleteResponseR,
  AKBECommMaintainUserGroupUpdateResponseR,
  AKBECommSelectUserGroupFilter,
  AKBECommUserGroupDirectoriesGetResponseR,
  AKBECommUserGroupChildGroupCreateResponseR,
  AKBECommUserGroupChildGroupDeleteResponseR,
  AKBECommUserGroupDeleteResponseR,
  AKBECommUserGroupDirectoryCreateResponseR,
  AKBECommUserGroupDirectoryDeleteResponseR,
  AKBECommUserGroupDirectoryGetResponseR,
  AKBECommUserGroupDirectoryUpdateRequest,
  AKBECommUserGroupDirectoryUpdateResponseR,
  AKBECommUserGroupGetResponseR,
  AKBECommUserGroupParentGroupCreateResponseR,
  AKBECommUserGroupParentGroupDeleteResponseR,
  AKBECommUserGroupPersonRoleCreateResponseR,
  AKBECommUserGroupPersonRoleDeleteResponseR,
  AKBECommUserGroupPersonRoleUpdateRequest,
  AKBECommUserGroupPersonRoleUpdateResponseR,
  AKBECommUserGroupSchema,
  AKBECommUserGroupsCreateResponseR,
  AKBECommUserGroupsGetRequest,
  AKBECommUserGroupsGetResponseR,
  AKBECommUserGroupSourceTypeCreateResponseR,
  AKBECommUserGroupSourceTypeDeleteResponseR,
  AKBECommUserGroupSourceTypeGetResponseR,
  AKBECommUserGroupSourceTypesGetResponseR,
  AKBECommUserGroupSourceTypeUpdateRequest,
  AKBECommUserGroupSourceTypeUpdateResponseR,
  AKBECommUserGroupTypeCreateResponseR,
  AKBECommUserGroupTypeDeleteResponseR,
  AKBECommUserGroupTypeGetResponseR,
  AKBECommUserGroupTypesGetResponseR as AKBECommUserGroupTypesResponseR,
  AKBECommUserGroupTypeUpdateRequest,
  AKBECommUserGroupTypeUpdateResponseR,
  AKBECommUserGroupUpdateRequest,
  AKBECommUserRolesGetResponseR,
} from '@ak_tools/backend_communication/definitions/users/AKBECommUsers';

export class AKBECommUsers extends BeCommAll {
  public beCommGenerateAndVerifyUserGroupName(
    text: string,
    directoryId: number,
    purpose: string,
    suggestion: string,
  ): Promise<AKBECommGenerateAndVerifyUserGroupNameResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.generateAndVerifyUserGroups.message'),
    };
    const postBody: AKBECommGenerateAndVerifyUserGroupNameRequest = {
      text: text,
      directory_id: directoryId,
      purpose: purpose,
      suggestion: suggestion,
    };
    return this.beComm.backendCommPost(
      '/ak-users-cmds/generate-and-verify-user-group-name',
      postBody,
      { timeout: 40000 },
      messages,
    );
  }
  public beCommGenerateAndVerifyUserGroupDirectoryName(
    text: string,
    directoryId: number,
    purpose: string,
    suggestion: string,
  ): Promise<AKBECommGenerateAndVerifyUserGroupDirectoryNameResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.generateAndVerifyUserGroups.message'),
    };
    const postBody: AKBECommGenerateAndVerifyUserGroupDirectoryNameRequest = {
      text: text,
      purpose: purpose,
      directory_id: directoryId,
      suggestion: suggestion,
    };
    return this.beComm.backendCommPost(
      '/ak-users-cmds/generate-and-verify-user-group-directory-name',
      postBody,
      { timeout: 40000 },
      messages,
    );
  }
  //  ------------------------------------------------------------------------------------------------------------------
  //  user groups
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommGetUserGroupsByFilter(
    filter: AKBECommSelectUserGroupFilter,
    excluded_user_group_ids: Array<number> = [],
  ): Promise<AKBECommUserGroupsGetResponseR> {
    const postBody: AKBECommUserGroupsGetRequest = {
      selected_filter: filter,
      excluded_user_group_ids,
    };

    const messages: AKBECommBaseMessages = {
      error: 'Fehler beim Laden der User Gruppen',
    };

    return this.beComm.backendCommPost('/ak-users-cmds/get-groups-by-filter', postBody, { timeout: 30000 }, messages);
  }

  public beCommCreateUserGroup(): Promise<AKBECommUserGroupsCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroups.message'),
    };
    return this.beComm.backendCommPost('/ak-users/groups', {}, {}, messages);
  }

  public beCommGetUserGroupById(groupId: number): Promise<AKBECommUserGroupGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroup.message'),
    };
    return this.beComm.backendCommGet(`/ak-users/groups/${groupId}`, { timeout: 40000 }, messages);
  }

  public beCommUpdateUserGroupById(groupData: AKBECommUserGroupSchema): Promise<AKBECommUserGroupGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.put.userGroup.message'),
    };

    const putBody: AKBECommUserGroupUpdateRequest = {
      display_name: groupData.display_name,
      description: groupData.description,
      abbr: groupData.abbr,
      title: groupData.title,
      active: groupData.active,
      private: groupData.private,
      restrict_user_visibility: groupData.restrict_user_visibility,
      directory_id: groupData.directory?.directory_id || null,
    };

    return this.beComm.backendCommPut(`/ak-users/groups/${groupData.user_group_id}`, putBody, {}, messages);
  }

  public beCommDeleteUserGroupById(groupId: number): Promise<AKBECommUserGroupDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroup.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/groups/${groupId}`, {}, messages);
  }

  //  ------------------------------------------------------------------------------------------------------------------
  //  user group con user con role
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommCreateUserGroupConUserConRole(
    groupId: number,
    userId: number,
    roleId: number,
  ): Promise<AKBECommUserGroupPersonRoleCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupConUserConRole.message'),
    };
    return this.beComm.backendCommPost(`/ak-users/groups/${groupId}/users/${userId}/roles/${roleId}`, {}, {}, messages);
  }

  public beCommUpdateUserGroupConUserConRole(
    groupId: number,
    userGroupChildId: number,
    roleId: number,
    active: boolean,
  ): Promise<AKBECommUserGroupPersonRoleUpdateResponseR> {
    const putBody: AKBECommUserGroupPersonRoleUpdateRequest = {
      active,
    };

    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.put.userGroupConUserConRole.message'),
    };
    return this.beComm.backendCommPut(
      `/ak-users/groups/${groupId}/users/${userGroupChildId}/roles/${roleId}`,
      putBody,
      {},
      messages,
    );
  }

  public beCommDeleteUserGroupConUserConRole(
    groupId: number,
    userId: number,
    roleId: number,
  ): Promise<AKBECommUserGroupPersonRoleDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupConUserConRole.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/groups/${groupId}/users/${userId}/roles/${roleId}`, {}, messages);
  }

  //  ------------------------------------------------------------------------------------------------------------------
  //  user group con parent group
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommCreateUserGroupConParentGroup(
    groupId: number,
    parentGroupId: number,
  ): Promise<AKBECommUserGroupParentGroupCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupConParentGroup.message'),
    };
    return this.beComm.backendCommPost(`/ak-users/groups/${groupId}/parent-groups/${parentGroupId}`, {}, {}, messages);
  }

  public beCommDeleteUserGroupConParentGroup(
    groupId: number,
    parentGroupId: number,
  ): Promise<AKBECommUserGroupParentGroupDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupConParentGroup.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/groups/${groupId}/parent-groups/${parentGroupId}`, {}, messages);
  }

  //  ------------------------------------------------------------------------------------------------------------------
  //  user group con Maintain groups con role
  //  ------------------------------------------------------------------------------------------------------------------

  public beCommCreateOrUpdateUserGroupConMaintainGroupConRole(
    groupId: number,
    userGroupMaintainId: number,
    roleId: number,
    active: boolean,
  ): Promise<AKBECommMaintainUserGroupUpdateResponseR> {
    const putBody: AKBECommUserGroupPersonRoleUpdateRequest = {
      active,
    };

    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.put.userGroupConMaintainConRole.message'),
    };
    return this.beComm.backendCommPut(
      `/ak-users/groups/${groupId}/maintain-groups/${userGroupMaintainId}/roles/${roleId}`,
      putBody,
      {},
      messages,
    );
  }

  public beCommDeleteUserGroupConMaintainGroupConRole(
    groupId: number,
    maintainGroupId: number,
    roleId: number,
  ): Promise<AKBECommMaintainUserGroupDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupConMaintainConRole.message'),
    };
    return this.beComm.backendCommDelete(
      `/ak-users/groups/${groupId}/maintain-groups/${maintainGroupId}/roles/${roleId}`,
      {},
      messages,
    );
  }
  //  ------------------------------------------------------------------------------------------------------------------
  //  user group con child group
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommCreateUserGroupConChildGroup(
    groupId: number,
    childGroupId: number,
    roleId: number,
  ): Promise<AKBECommUserGroupChildGroupCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupConChildGroup.message'),
    };
    return this.beComm.backendCommPut(
      `/ak-users/groups/${groupId}/child-groups/${childGroupId}/roles/${roleId}`,
      {},
      {},
      messages,
    );
  }

  public beCommDeleteUserGroupConChildGroup(
    groupId: number,
    childGroupId: number,
    roleId: number,
  ): Promise<AKBECommUserGroupChildGroupDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupConChildGroup.message'),
    };
    return this.beComm.backendCommDelete(
      `/ak-users/groups/${groupId}/child-groups/${childGroupId}/roles/${roleId}`,
      {},
      messages,
    );
  }

  //  ------------------------------------------------------------------------------------------------------------------
  //  user roles
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommGetUserRoles(): Promise<AKBECommUserRolesGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userRoles.message'),
    };
    return this.beComm.backendCommGet('/ak-users/roles', {}, messages);
  }
  public beCommGetUserAdminGroups(userId: number): Promise<AKBECommUserGroupsGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userAdminGroups.message'),
    };
    return this.beComm.backendCommGet(`/ak-users-cmds/user-admin-groups/${userId}`, {}, messages);
  }

  //  ------------------------------------------------------------------------------------------------------------------
  //  user group types + user group source types
  //  ------------------------------------------------------------------------------------------------------------------
  public beCommGetUserGroupTypes(): Promise<AKBECommUserGroupTypesResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupTypes.message'),
    };
    return this.beComm.backendCommGet('/ak-users/user-group-types', {}, messages);
  }
  public beCommCreateUserGroupType(): Promise<AKBECommUserGroupTypeCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupType.message'),
    };
    return this.beComm.backendCommPost('/ak-users/user-group-types', {}, {}, messages);
  }
  public beCommDeleteUserGroupTypeById(userGroupTypeId: number): Promise<AKBECommUserGroupTypeDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupType.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/user-group-types/${userGroupTypeId}`, {}, messages);
  }
  public beCommUpdateUserGroupType(
    userGroupTypeSchema: AKBECommUserGroupTypeUpdateRequest,
    userGroupTypeId: number,
  ): Promise<AKBECommUserGroupTypeUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.put.userGroupType.message'),
    };
    return this.beComm.backendCommPut(
      `/ak-users/user-group-types/${userGroupTypeId}`,
      userGroupTypeSchema,
      {},
      messages,
    );
  }
  public beCommGetUserGroupTypeById(userGroupTypeId: number): Promise<AKBECommUserGroupTypeGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupType.message'),
    };
    return this.beComm.backendCommGet(`/ak-users/user-group-types/${userGroupTypeId}`, {}, messages);
  }

  public beCommGetUserGroupSourceTypes(): Promise<AKBECommUserGroupSourceTypesGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupSourceTypes.message'),
    };
    return this.beComm.backendCommGet('/ak-users/user-group-source-types', {}, messages);
  }
  public beCommCreateUserGroupSourceType(): Promise<AKBECommUserGroupSourceTypeCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupSourceType.message'),
    };
    return this.beComm.backendCommPost('/ak-users/user-group-source-types', {}, {}, messages);
  }
  public beCommDeleteUserGroupSourceTypeById(
    userGroupSourceTypeId: number,
  ): Promise<AKBECommUserGroupSourceTypeDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupSourceType.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/user-group-source-types/${userGroupSourceTypeId}`, {}, messages);
  }
  public beCommGetUserGroupSourceTypeById(
    userGroupSourceTypeId: number,
  ): Promise<AKBECommUserGroupSourceTypeGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupSourceType.message'),
    };
    return this.beComm.backendCommGet(`/ak-users/user-group-source-types/${userGroupSourceTypeId}`, {}, messages);
  }
  public beCommUpdateUserGroupSourceType(
    userGroupSourceTypeSchema: AKBECommUserGroupSourceTypeUpdateRequest,
    userGroupSourceTypeId: number,
  ): Promise<AKBECommUserGroupSourceTypeUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.put.userGroupSourceType.message'),
    };
    return this.beComm.backendCommPut(
      `/ak-users/user-group-source-types/${userGroupSourceTypeId}`,
      userGroupSourceTypeSchema,
      {},
      messages,
    );
  }
  //  ------------------------------------------------------------------------------------------------------------------
  //  user roles
  //  ------------------------------------------------------------------------------------------------------------------

  public beCommGetUserGroupDirectories(): Promise<AKBECommUserGroupDirectoriesGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupCategories.message'),
    };
    return this.beComm.backendCommGet('/ak-users/user-group-directories', {}, messages);
  }
  public beCommCreateUserGroupDirectory(): Promise<AKBECommUserGroupDirectoryCreateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.post.userGroupDirectory.message'),
    };
    return this.beComm.backendCommPost('/ak-users/user-group-directories', {}, {}, messages);
  }
  public beCommDeleteUserGroupDirectoryById(
    userGroupDirectoryId: number,
  ): Promise<AKBECommUserGroupDirectoryDeleteResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.delete.userGroupDirectory.message'),
    };
    return this.beComm.backendCommDelete(`/ak-users/user-group-directories/${userGroupDirectoryId}`, {}, messages);
  }
  public beCommGetUserGroupDirectoryById(
    userGroupDirectoryId: number,
  ): Promise<AKBECommUserGroupDirectoryGetResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.get.userGroupDirectory.message'),
    };
    return this.beComm.backendCommGet(`/ak-users/user-group-directories/${userGroupDirectoryId}`, {}, messages);
  }
  public beCommUpdateUserGroupDirectory(
    userGroupDirectorySchema: AKBECommUserGroupDirectoryUpdateRequest,
    userGroupDirectoryId: number,
  ): Promise<AKBECommUserGroupDirectoryUpdateResponseR> {
    const messages: AKBECommBaseMessages = {
      error: this.vueProvider.t('AKBECommUsers.error.put.userGroupDirectory.message'),
    };
    return this.beComm.backendCommPut(
      `/ak-users/user-group-directories/${userGroupDirectoryId}`,
      userGroupDirectorySchema,
      {},
      messages,
    );
  }
}
